"use client";

import { useMediaQuery } from "usehooks-ts";
import React, { useContext } from "react";
import { useRouter } from "next/navigation";
import { PersonIcon } from "@radix-ui/react-icons";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import NavbarItem from "./NavbarItem";
import { switchRole } from "@/app/actions";
import { UserAuth } from "../../app/context/AuthContext";
import { cn } from "@/lib/utils";
import LoadingOverlayContextProvider, { LoadingOverlayContext } from "@/app/context/LoadingOverlayContextProvider";

export function NavbarC({ children, className }) {
  const { isLoggedIn } = UserAuth();
  return (
    <div className={cn(className, isLoggedIn ? "" : "hidden")}>{children}</div>
  );
}

export function RoleItemC({ trigger, content }) {
  const meetsXlBreakpoint = useMediaQuery("(min-width : 1280px)");
  return (
    <Popover>
      <PopoverTrigger>
        {trigger}
      </PopoverTrigger>
      <PopoverContent side={meetsXlBreakpoint ? "right" : "top"}>
        {content}
      </PopoverContent>
    </Popover>
  );
}

async function switchRoleHandler(roleData, router, setIsLoading) {
  setIsLoading(true);
  await switchRole(roleData);
  router.refresh();
  // window.location.reload();
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);
}

export function RoleC({ children, data, isActive }) {
  const router = useRouter();
  const { setIsLoading } = useContext(LoadingOverlayContext);
  return (
    <button
      type="button"
      disabled={isActive}
      className="bg-none"
      onClick={async () => switchRoleHandler(data, router, setIsLoading)}
    >
      {children}
    </button>
  );
}
