import(/* webpackMode: "eager", webpackExports: ["AuthContextProvider"] */ "/codebuild/output/src3309536860/src/astra-terminal/app/context/AuthContext.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingOverlayContextProvider"] */ "/codebuild/output/src3309536860/src/astra-terminal/app/context/LoadingOverlayContextProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3309536860/src/astra-terminal/app/context/QueryClientContextProvider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3309536860/src/astra-terminal/app/context/SubscriptionContext.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["WsContextProvider"] */ "/codebuild/output/src3309536860/src/astra-terminal/app/context/WsContextProvider.jsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3309536860/src/astra-terminal/app/globals.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3309536860/src/astra-terminal/components/navbar/client.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3309536860/src/astra-terminal/components/navbar/LogOutItem.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea","ScrollBar"] */ "/codebuild/output/src3309536860/src/astra-terminal/components/ui/scroll-area.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider","Tooltip","TooltipTrigger","TooltipContent"] */ "/codebuild/output/src3309536860/src/astra-terminal/components/ui/tooltip.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleOAuthProvider"] */ "/codebuild/output/src3309536860/src/astra-terminal/node_modules/@react-oauth/google/dist/index.esm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3309536860/src/astra-terminal/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3309536860/src/astra-terminal/node_modules/next/font/google/target.css?{\"path\":\"app/font.js\",\"import\":\"IBM_Plex_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-mono\",\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"ibmPlexMono\"}");
