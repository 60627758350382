import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

const NavbarItem = React.forwardRef(
  ({ Icon, label, className, ...props }, ref) => (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger asChild>
          {/* If we don't have this outer div here, some buttons don't get sized correctly */}
          <div>
            <span
              className={cn(
                "flex size-10 items-center justify-center rounded hover:bg-white hover:text-black active:bg-gray-500",
                className,
              )}
              ref={ref}
              {...props}
            >
              <Icon className="size-7 md:size-5" />
            </span>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{label}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ),
);
NavbarItem.displayName = "MenuItem";

export default NavbarItem;
