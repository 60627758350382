"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createContext, useMemo, useState } from "react";
import { cn } from "@/lib/utils";

export const LoadingOverlayContext = createContext();

function LoadingSpinner({ className }) {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-center bg-black/25">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={cn("animate-spin", className)}
      >
        <path d="M21 12a9 9 0 1 1-6.219-8.56" />
      </svg>
    </div>
  );
};

export function LoadingOverlayContextProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const params = useMemo(() => ({ isLoading, setIsLoading }), [isLoading, setIsLoading]);
  return (
    <LoadingOverlayContext.Provider value={params}>
      {isLoading && <LoadingSpinner />}
      {children}
    </LoadingOverlayContext.Provider>
  );
}
