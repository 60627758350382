"use client";

import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ASTRA_BASE_WS_URL } from "@/constants";

const WsContext = createContext();

export function WsContextProvider({ children }) {
  const connsRef = useRef({});

  return <WsContext.Provider value={connsRef}>{children}</WsContext.Provider>;
}

function getOrInitWsConn(connsRef, queryString) {
  if (queryString in connsRef.current) {
    return connsRef.current[queryString];
  }
  const ws = new WebSocket(`${ASTRA_BASE_WS_URL}?${queryString}`);
  // eslint-disable-next-line no-param-reassign
  connsRef.current[queryString] = ws;
  return ws;
}

export function useWsContext(queryString, { samplingIntervalMillis }) {
  const latestMsgRef = useRef(null);
  const [sampledMsg, setSampledMsg] = useState(null);
  const connsRef = useContext(WsContext);

  useEffect(() => {
    const ws = getOrInitWsConn(connsRef, queryString);
    const onMessage = (e) => {
      const msg = JSON.parse(e?.data);
      latestMsgRef.current = msg;
    };
    ws.addEventListener("message", onMessage);

    const intervalId = setInterval(() => {
      setSampledMsg(latestMsgRef.current);
    }, samplingIntervalMillis);

    return () => {
      ws.removeEventListener("message", onMessage);
      clearInterval(intervalId);
    };
  }, []);

  return sampledMsg;
}
