"use client";

import { ExitIcon } from "@radix-ui/react-icons";
import { UserAuth } from "@/app/context/AuthContext";
import NavbarItem from "./NavbarItem";

export default function LogOutItem() {
  const { logOut } = UserAuth();
  return (
    <NavbarItem
      className="cursor-pointer hover:text-red-600"
      Icon={ExitIcon}
      label="Log out"
      onClick={logOut}
    />
  );
}
